<template>
  <div>
    <Header  :tabActive="2" />
    <ScrollSmall :pageSmallTile="pageSmallTile" />
    <ProductContentNew />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header';
import ScrollSmall from '../components/ScrollSmall';
import ProductContentNew from '../components/product/Product-content-new';
import Footer from '../components/Footer';

export default {
  name: 'Product',
  components: {
    Header,
    ScrollSmall,
    ProductContentNew,
    Footer
  },
  data () {
    return {
      pageSmallTile: {
        title: '产品系列',
        url: 'http://img.sicred.cn/assets/img/banner/product.png',
        tis: [
          {
            name:'PRODUCTS',
            url:'#/product'
          }
        ]
      }
    }
  }
}
</script>

<style>
</style>
