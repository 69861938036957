<template>
    <section class="product-table">
        <div>
            <div class="entry-content-page">
                <div id="tablepress-scroll-wrapper" class="tablepress-scroll-wrapper">
                    <div id="tablepress-wrapper" class="dataTables_wrapper dataTables_wrapper_diode no-footer">
                        <div id="tablepress_wrapper" class="dataTables_wrapper no-footer">
                            <div class="dataTables_scroll" id="tablepress_length">
                                <el-select multiple collapse-tags v-model="VDSValue" placeholder="VDS" clearable
                                    @change="changeSelect('th2')">
                                    <el-option :disabled="item.disabled" v-for="item in VDSOptions" :key="item.value"
                                        :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-select multiple collapse-tags v-model="RDSOValue" placeholder="RDS(ON)" clearable
                                    @change="changeSelect('th3')">
                                    <el-option :disabled="item.disabled" v-for="item in RDSOptions" :key="item.value"
                                        :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-select multiple collapse-tags v-model="IDValue" placeholder="ID(A)" clearable
                                    @change="changeSelect('th4')">
                                    <el-option :disabled="item.disabled" v-for="item in IDOptions" :key="item.value"
                                        :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-select multiple collapse-tags v-model="PackageValue" placeholder="Package" clearable
                                    @change="changeSelect('th6')">
                                    <el-option :disabled="item.disabled" v-for="item in PackageOptions" :key="item.value"
                                        :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-button type="primary" @click="org()">重置</el-button>
                            </div>
                            <div class="dataTables_scroll">
                                <el-table :data="showDataList" stripe height="500" style="width: 100%;margin-bottom:50px">
                                    <el-table-column prop="th1" align="center" label="Part Number" width="160">
                                    </el-table-column>
                                    <el-table-column prop="th2" align="center" label="Voltage(V)" width="120">
                                    </el-table-column>
                                    <el-table-column prop="th3" align="center" label="RDS(ON)(mΩ)" width="120">
                                    </el-table-column>
                                    <el-table-column prop="th4" align="center" label="ID(A)">
                                    </el-table-column>
                                    <el-table-column prop="th5" align="center" label="Qg(Typ.)">
                                    </el-table-column>
                                    <el-table-column prop="th6" align="center" label="Package">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "App",
    components: {},
    data() {
        return {
            //
            dataList: [
                {
                    th1: "SMC230SE5N075MBH1",
                    th2: "750",
                    th3: "5",
                    th4: "251(wafer ability)*",
                    th5: "582",
                    th6: "SOT-227",
                },
                {
                    th1: "SMC190SE7N120MBH1",
                    th2: "1200",
                    th3: "7",
                    th4: "188*",
                    th5: "560",
                    th6: "SOT-227",
                },
                {
                    th1: "SMC130SE11N075MBH1",
                    th2: "750",
                    th3: "11",
                    th4: "117*",
                    th5: "268",
                    th6: "SOT-227",
                },
                {
                    th1: "SMC130SE11N075DMBH1",
                    th2: "750",
                    th3: "11",
                    th4: "117*",
                    th5: "268",
                    th6: "SOT-227",
                },
                {
                    th1: "SMC11N075T4AS",
                    th2: "750",
                    th3: "11",
                    th4: "218(wafer ability)",
                    th5: "268",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC11N075T3AS",
                    th2: "750",
                    th3: "11",
                    th4: "218(wafer ability)",
                    th5: "268",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC16N120K7AS",
                    th2: "1200",
                    th3: "16",
                    th4: "104",
                    th5: "247",
                    th6: "T2PAK",
                },
                {
                    th1: "SMC70SE16N120MBH1",
                    th2: "1200",
                    th3: "16",
                    th4: "72*",
                    th5: "247",
                    th6: "SOT-227",
                },
                {
                    th1: "SMC70SE16N120DMBH1",
                    th2: "1200",
                    th3: "16",
                    th4: "72*",
                    th5: "247",
                    th6: "SOT-227",
                },
                {
                    th1: "SMC70SE16N120MBH3",
                    th2: "1200",
                    th3: "16",
                    th4: "65*",
                    th5: "221",
                    th6: "SOT-227",
                },
                {
                    th1: "SMC16N120T4DA",
                    th2: "1200",
                    th3: "16",
                    th4: "140",
                    th5: "221",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC16N120L8AS",
                    th2: "1200",
                    th3: "16",
                    th4: "76",
                    th5: "247",
                    th6: "TOLL",
                },
                {
                    th1: "SMC16N120T4AA",
                    th2: "1200",
                    th3: "16",
                    th4: "150",
                    th5: "247",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC16N120T3AS",
                    th2: "1200",
                    th3: "16",
                    th4: "150",
                    th5: "247",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC25N065L8AS",
                    th2: "650",
                    th3: "26",
                    th4: "114",
                    th5: "138",
                    th6: "TOLL",
                },
                {
                    th1: "SMC25N065MAS",
                    th2: "650",
                    th3: "26",
                    th4: "88",
                    th5: "138",
                    th6: "TO-220-3",
                },
                {
                    th1: "SMC25N065D88AS",
                    th2: "650",
                    th3: "26",
                    th4: "89",
                    th5: "138",
                    th6: "DFN8x8",
                },
                {
                    th1: "SMC25N065MBS",
                    th2: "650",
                    th3: "26",
                    th4: "TBD",
                    th5: "TBD",
                    th6: "TO-220-3",
                },
                {
                    th1: "SMC25N065D88BS",
                    th2: "650",
                    th3: "26",
                    th4: "71",
                    th5: "74.5",
                    th6: "DFN8x8",
                },
                {
                    th1: "SMC25N065T4BS",
                    th2: "650",
                    th3: "26",
                    th4: "TBD",
                    th5: "TBD",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC25N065T3BS",
                    th2: "650",
                    th3: "26",
                    th4: "99",
                    th5: "74.5",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC25N065HBS",
                    th2: "650",
                    th3: "26",
                    th4: "73",
                    th5: "74.5",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC25N065K7AS",
                    th2: "650",
                    th3: "26",
                    th4: "84",
                    th5: "74.5",
                    th6: "T2PAK",
                },
                {
                    th1: "SMC25N065HAS",
                    th2: "650",
                    th3: "26",
                    th4: "90",
                    th5: "138",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC25N065T4AS",
                    th2: "650",
                    th3: "26",
                    th4: "105",
                    th5: "138",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC25N065T3AS",
                    th2: "650",
                    th3: "26",
                    th4: "105",
                    th5: "138",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC25N065FAS",
                    th2: "650",
                    th3: "26",
                    th4: "84",
                    th5: "138",
                    th6: "TO-220F",
                },
                {
                    th1: "SMC50SE28N120MBH1",
                    th2: "1200",
                    th3: "28",
                    th4: "48*",
                    th5: "137",
                    th6: "SOT-227",
                },
                {
                    th1: "SMC28N120HAS",
                    th2: "1200",
                    th3: "28",
                    th4: "85",
                    th5: "137",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC28N120T4AS",
                    th2: "1200",
                    th3: "28",
                    th4: "90",
                    th5: "137",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC28N120T3AS",
                    th2: "1200",
                    th3: "28",
                    th4: "90",
                    th5: "137",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC40SE32N120MBH1",
                    th2: "1200",
                    th3: "32",
                    th4: "43*",
                    th5: "88.1",
                    th6: "SOT-227",
                },
                {
                    th1: "SMC32N120D88AS",
                    th2: "1200",
                    th3: "32",
                    th4: "66",
                    th5: "88.1",
                    th6: "DFN8x8",
                },
                {
                    th1: "SMC32N120L8AS",
                    th2: "1200",
                    th3: "32",
                    th4: "59",
                    th5: "88.1",
                    th6: "TOLL",
                },
                {
                    th1: "SMC32N120HAS",
                    th2: "1200",
                    th3: "32",
                    th4: "67",
                    th5: "88.1",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC32N120T4BA",
                    th2: "1200",
                    th3: "32",
                    th4: "69",
                    th5: "88.1",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC40N120D88AS",
                    th2: "1200",
                    th3: "35",
                    th4: "63",
                    th5: "87",
                    th6: "DFN8x8",
                },
                {
                    th1: "SMC40N120HAA",
                    th2: "1200",
                    th3: "35",
                    th4: "67",
                    th5: "87",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC40N120T4AA",
                    th2: "1200",
                    th3: "35",
                    th4: "68",
                    th5: "87",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC40N120T3AA",
                    th2: "1200",
                    th3: "35",
                    th4: "68",
                    th5: "87",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC40N120T4CA",
                    th2: "1200",
                    th3: "36",
                    th4: "66",
                    th5: "129",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC40N120HCA",
                    th2: "1200",
                    th3: "36",
                    th4: "61",
                    th5: "129",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC40N065L8AS",
                    th2: "650",
                    th3: "45",
                    th4: "63",
                    th5: "66.2",
                    th6: "TOLL",
                },
                {
                    th1: "SMC45N065K7AS",
                    th2: "650",
                    th3: "45",
                    th4: "59",
                    th5: "42.3",
                    th6: "T2PAK",
                },
                {
                    th1: "SMC40N065D88AC",
                    th2: "650",
                    th3: "45",
                    th4: "68",
                    th5: "66.2",
                    th6: "DFN8x8",
                },
                {
                    th1: "SMC40N065HAS",
                    th2: "650",
                    th3: "45",
                    th4: "59",
                    th5: "66.2",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC40N065T4AS",
                    th2: "650",
                    th3: "45",
                    th4: "55",
                    th5: "66.2",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC50N065FAS",
                    th2: "650",
                    th3: "45",
                    th4: "46",
                    th5: "72",
                    th6: "TO-220F",
                },
                {
                    th1: "SMC40N065T3AS",
                    th2: "650",
                    th3: "45",
                    th4: "55",
                    th5: "66.2",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC60N065L8AS",
                    th2: "650",
                    th3: "55",
                    th4: "62",
                    th5: "66.2",
                    th6: "TOLL",
                },
                {
                    th1: "SMC60N065HAS",
                    th2: "650",
                    th3: "55",
                    th4: "48",
                    th5: "66.2",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC60N065T4AS",
                    th2: "650",
                    th3: "55",
                    th4: "54",
                    th5: "54",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC40N065DT4AS",
                    th2: "650",
                    th3: "58",
                    th4: "55",
                    th5: "42",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC80N120L8AS",
                    th2: "1200",
                    th3: "68",
                    th4: "42",
                    th5: "44.8",
                    th6: "TOLL",
                },
                {
                    th1: "SMC80N120HAA",
                    th2: "1200",
                    th3: "68",
                    th4: "34",
                    th5: "44.8",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC80N120DHBA",
                    th2: "1200",
                    th3: "68",
                    th4: "34",
                    th5: "80",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC80N120T4AA",
                    th2: "1200",
                    th3: "68",
                    th4: "41",
                    th5: "44.8",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC80N120DT4AS",
                    th2: "1200",
                    th3: "68",
                    th4: "41",
                    th5: "54",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC80N120T3AA",
                    th2: "1200",
                    th3: "68",
                    th4: "41",
                    th5: "44.8",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC80N120DT3AS",
                    th2: "1200",
                    th3: "68",
                    th4: "41",
                    th5: "54",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC20SE80N120MBH1",
                    th2: "1200",
                    th3: "68",
                    th4: "31*",
                    th5: "44.8",
                    th6: "SOT-227",
                },
                {
                    th1: "SMC80N220T4AS",
                    th2: "2200",
                    th3: "80",
                    th4: "TBD",
                    th5: "TBD",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC80N220T3AS",
                    th2: "2200",
                    th3: "80",
                    th4: "TBD",
                    th5: "TBD",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC80N220HAS",
                    th2: "2200",
                    th3: "80",
                    th4: "TBD",
                    th5: "TBD",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC107N065T4AS",
                    th2: "650",
                    th3: "107",
                    th4: "TBD",
                    th5: "TBD",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC107N065T3AS",
                    th2: "650",
                    th3: "107",
                    th4: "TBD",
                    th5: "TBD",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC107N065HAS",
                    th2: "650",
                    th3: "107",
                    th4: "TBD",
                    th5: "TBD",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC107N065L8AS",
                    th2: "650",
                    th3: "107",
                    th4: "TBD",
                    th5: "TBD",
                    th6: "TOLL",
                },
                {
                    th1: "SMC160N120L8AS",
                    th2: "1200",
                    th3: "165",
                    th4: "25",
                    th5: "41",
                    th6: "TOLL",
                },
                {
                    th1: "SMC160N120HAS",
                    th2: "1200",
                    th3: "165",
                    th4: "21",
                    th5: "41",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC160N120T4AS",
                    th2: "1200",
                    th3: "165",
                    th4: "23",
                    th5: "41",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC160N120T3AS",
                    th2: "1200",
                    th3: "165",
                    th4: "23",
                    th5: "41",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC160N120D88AS",
                    th2: "1200",
                    th3: "165",
                    th4: "22",
                    th5: "41",
                    th6: "DFN8x8",
                },
                {
                    th1: "SMC180N080DAS",
                    th2: "800",
                    th3: "190",
                    th4: "19",
                    th5: "17.6",
                    th6: "TO-252",
                },
                {
                    th1: "SMC180N080FAS",
                    th2: "800",
                    th3: "190",
                    th4: "11",
                    th5: "17.6",
                    th6: "TO-220F",
                },
                {
                    th1: "SMC180N080MAS",
                    th2: "800",
                    th3: "190",
                    th4: "20",
                    th5: "17.6",
                    th6: "TO-220-3",
                },
                {
                    th1: "SMC180N080D88AS",
                    th2: "800",
                    th3: "190",
                    th4: "28",
                    th5: "17.6",
                    th6: "DFN8x8",
                },
                {
                    th1: "SMC180N080D56AS",
                    th2: "800",
                    th3: "190",
                    th4: "25",
                    th5: "17.6",
                    th6: "DFN5x6",
                },
                {
                    th1: "SMC200N090FAS",
                    th2: "900",
                    th3: "200",
                    th4: "11",
                    th5: "17.6",
                    th6: "TO-220F",
                },
                {
                    th1: "SMC200N090MAS",
                    th2: "900",
                    th3: "200",
                    th4: "20",
                    th5: "17.6",
                    th6: "TO-220-3",
                },
                {
                    th1: "SMC300N065FAS",
                    th2: "650",
                    th3: "300",
                    th4: "7",
                    th5: "11.6",
                    th6: "TO-220-F",
                },
                {
                    th1: "SMC300N065D56AS",
                    th2: "650",
                    th3: "300",
                    th4: "13",
                    th5: "11.6",
                    th6: "DFN5x6",
                },
                {
                    th1: "SMC300N065MAS",
                    th2: "650",
                    th3: "300",
                    th4: "13",
                    th5: "11.6",
                    th6: "TO-220-3",
                },
                {
                    th1: "SMC300N065D88AS",
                    th2: "650",
                    th3: "300",
                    th4: "14",
                    th5: "11.6",
                    th6: "DFN8x8",
                },
                {
                    th1: "SMC300N065DAS",
                    th2: "650",
                    th3: "300",
                    th4: "12",
                    th5: "11.6",
                    th6: "TO-252",
                },
                {
                    th1: "SMC1R0N170HAS",
                    th2: "1700",
                    th3: "800",
                    th4: "7.3",
                    th5: "19.8",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC1R0N170T4AS",
                    th2: "1700",
                    th3: "800",
                    th4: "8.4",
                    th5: "19.8",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC1R0N170MAS",
                    th2: "1700",
                    th3: "800",
                    th4: "7.3",
                    th5: "19.8",
                    th6: "TO-220-3",
                },
                {
                    th1: "SMC1R0N170T3AS",
                    th2: "1700",
                    th3: "800",
                    th4: "8.4",
                    th5: "19.8",
                    th6: "TO-247-3",
                }, {
                    th1: "SMC90N065L8AS",
                    th2: "650",
                    th3: "90/100@18V",
                    th4: "36",
                    th5: "35.8",
                    th6: "TOLL",
                },
                {
                    th1: "SMC90N065HAS",
                    th2: "650",
                    th3: "90/100@18V",
                    th4: "31",
                    th5: "35.8",
                    th6: "TO-263-7",
                },
                {
                    th1: "SMC90N065T4AS",
                    th2: "650",
                    th3: "90/100@18V",
                    th4: "32",
                    th5: "35.8",
                    th6: "TO-247-4",
                },
                {
                    th1: "SMC90N065T3AS",
                    th2: "650",
                    th3: "90/100@18V",
                    th4: "32",
                    th5: "35.8",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC90N065DT3AS",
                    th2: "650",
                    th3: "90/100@18V",
                    th4: "32",
                    th5: "36",
                    th6: "TO-247-3",
                },
                {
                    th1: "SMC90N065MAS",
                    th2: "650",
                    th3: "90/100@18V",
                    th4: "30",
                    th5: "35.8",
                    th6: "TO-220-3",
                }, {
                    th1: "SMC90N065D88AC",
                    th2: "650",
                    th3: "90/100@18V",
                    th4: "37",
                    th5: "35.8",
                    th6: "DFN8x8",
                },
            ],
            VDSValue: '', // 第一个select数据
            RDSOValue: '', // 第二个select数据
            IDValue: '', // 第三个select数据
            PackageValue: '', // 第四个select数据
            VDSOptions: [
                {
                    value: '650',
                    label: '650'
                },
                {
                    value: '750',
                    label: '750'
                },
                {
                    value: '800',
                    label: '800'
                },
                {
                    value: '900',
                    label: '900'
                },
                {
                    value: '1200',
                    label: '1200'
                },
                {
                    value: '1700',
                    label: '1700'
                },
                {
                    value: '2200',
                    label: '2200'
                },
            ],
            RDSOptions: [
                {
                    label: '5',
                    value: '5',
                    disabled: false
                },
                {
                    label: '7',
                    value: '7'
                },
                {
                    label: '11',
                    value: '11'
                },
                {
                    label: '16',
                    value: '16'
                },
                {
                    label: '26',
                    value: '26'
                },
                {
                    label: '28',
                    value: '28'
                },
                {
                    label: '32',
                    value: '32'
                },
                {
                    label: '35',
                    value: '35'
                },
                {
                    label: '36',
                    value: '36'
                },
                {
                    label: '45',
                    value: '45'
                },
                {
                    label: '55',
                    value: '55'
                },
                {
                    label: '58',
                    value: '58'
                },
                {
                    label: '68',
                    value: '68'
                },
                {
                    label: '80',
                    value: '80'
                },
                {
                    label: '107',
                    value: '107'
                },
                {
                    label: '165',
                    value: '165'
                },
                {
                    label: '190',
                    value: '190'
                },
                {
                    label: '200',
                    value: '200'
                },
                {
                    label: '300',
                    value: '300'
                },
                {
                    label: '800',
                    value: '800'
                },
                {
                    label: '900/100@18V',
                    value: '900/100@18V'
                },
            ],
            IDOptions: [
                {
                    value: '7',
                    label: '7'
                }, {
                    value: '7.3',
                    label: '7.3'
                }, {
                    value: '8.4',
                    label: '8.4'
                }, {
                    value: '13',
                    label: '13'
                }, {
                    value: '14',
                    label: '14'
                }, {
                    value: '21',
                    label: '21'
                }, {
                    value: '22',
                    label: '22'
                }, {
                    value: '23',
                    label: '23'
                }, {
                    value: '25',
                    label: '25'
                }, {
                    value: '28',
                    label: '28'
                }, {
                    value: '30',
                    label: '30'
                }, {
                    value: '31',
                    label: '31'
                }, {
                    value: '32',
                    label: '32'
                }, {
                    value: '34',
                    label: '34'
                }, {
                    value: '36',
                    label: '36'
                }, {
                    value: '37',
                    label: '37'
                }, {
                    value: '41',
                    label: '41'
                }, {
                    value: '42',
                    label: '42'
                }, {
                    value: '46',
                    label: '46'
                }, {
                    value: '48',
                    label: '48'
                }, {
                    value: '54',
                    label: '54'
                }, {
                    value: '55',
                    label: '55'
                }, {
                    value: '59',
                    label: '59'
                }, {
                    value: '62',
                    label: '62'
                }, {
                    value: '63',
                    label: '63'
                }, {
                    value: '66',
                    label: '66'
                }, {
                    value: '67',
                    label: '67'
                }, {
                    value: '68',
                    label: '68'
                }, {
                    value: '69',
                    label: '69'
                }, {
                    value: '76',
                    label: '76'
                }, {
                    value: '84',
                    label: '84'
                }, {
                    value: '85',
                    label: '85'
                }, {
                    value: '89',
                    label: '89'
                }, {
                    value: '90',
                    label: '90'
                }, {
                    value: '105',
                    label: '105'
                }, {
                    value: '114',
                    label: '114'
                }, {
                    value: '140',
                    label: '140'
                }, {
                    value: '150',
                    label: '150'
                }, {
                    value: '104',
                    label: '104'
                }, {
                    value: '11',
                    label: '11'
                }, {
                    value: '117*',
                    label: '117*'
                }, {
                    value: '12',
                    label: '12'
                }, {
                    value: '188*',
                    label: '188*'
                }, {
                    value: '19',
                    label: '19'
                }, {
                    value: '20',
                    label: '20'
                }, {
                    value: '218(wafer ability)',
                    label: '218(wafer ability)'
                }, {
                    value: '251(wafer ability)*',
                    label: '251(wafer ability)*'
                }, {
                    value: '31*',
                    label: '31*'
                }, {
                    value: '43*',
                    label: '43*'
                }, {
                    value: '48*',
                    label: '48*'
                }, {
                    value: '61',
                    label: '61'
                }, {
                    value: '65*',
                    label: '65*'
                }, {
                    value: '71',
                    label: '71'
                }, {
                    value: '72*',
                    label: '72*'
                }, {
                    value: '73',
                    label: '73'
                }, {
                    value: '88',
                    label: '88'
                }, {
                    value: '99',
                    label: '99'
                }, {
                    value: 'TBD',
                    label: 'TBD'
                },
            ],
            PackageOptions: [
                {
                    value: 'DFN5x6',
                    label: 'DFN5x6'
                }, {
                    value: 'DFN8x8',
                    label: 'DFN8x8'
                }, {
                    value: 'SOT-227',
                    label: 'SOT-227'
                }, {
                    value: 'T2PAK',
                    label: 'T2PAK'
                }, {
                    value: 'TO-220-3',
                    label: 'TO-220-3'
                }, {
                    value: 'TO-220-F',
                    label: 'TO-220-F'
                }, {
                    value: 'TO-220F',
                    label: 'TO-220F'
                }, {
                    value: 'TO-247-3',
                    label: 'TO-247-3'
                }, {
                    value: 'TO-247-4',
                    label: 'TO-247-4'
                }, {
                    value: 'TO-252',
                    label: 'TO-252'
                }, {
                    value: 'TO-263-7',
                    label: 'TO-263-7'
                }, {
                    value: 'TOLL',
                    label: 'TOLL'
                },
            ],
            showDataList: [],
            isFirst: null,
        }
    },
    mounted() {
        this.setData()
    },
    methods: {
        org() {
            this.VDSValue = ''; // 
            this.RDSOValue = ''; // 
            this.IDValue = ''; // 
            this.PackageValue = ''; // 
            this.isFirst = null;
            this.setData()
        },
        changeSelect(th) {
            if (!this.isFirst) {
                this.isFirst = th;
            }
            this.setData()
        },
        setData() {
            this.showDataList = JSON.parse(JSON.stringify(this.dataList));
            console.log(JSON.stringify(this.showDataList))
            if (this.VDSValue.length <= 0 && this.RDSOValue.length <= 0 && this.IDValue.length <= 0 && this.PackageValue.length <= 0) {
                this.isFirst = null;
            }
            if (this.VDSValue.length > 0) {
                this.showDataList = this.showDataList.filter(item => {
                    return this.VDSValue.includes(item.th2);
                })
            }
            if (this.RDSOValue.length > 0) {
                this.showDataList = this.showDataList.filter(item => {
                    return this.RDSOValue.includes(item.th3);
                })
            }
            if (this.IDValue.length > 0) {
                this.showDataList = this.showDataList.filter(item => {
                    return this.IDValue.includes(item.th4);
                })
            }
            if (this.PackageValue.length > 0) {
                this.showDataList = this.showDataList.filter(item => {
                    return this.PackageValue.includes(item.th6);
                })
            }
            if (this.isFirst) {
                if (this.isFirst != 'th2') {
                    let VDSArray = Array.from(new Set(this.showDataList.map(i => i.th2)));
                    this.VDSOptions.forEach(i => {
                        if (VDSArray.includes(i.value)) {
                            i.disabled = false;
                        } else {
                            i.disabled = true;
                        }
                    })
                }
                if (this.isFirst != 'th3') {
                    let RDSArray = Array.from(new Set(this.showDataList.map(i => i.th3)));
                    this.RDSOptions.forEach(i => {
                        if (RDSArray.includes(i.value)) {
                            i.disabled = false;
                        } else {
                            i.disabled = true;
                        }
                    })
                }
                if (this.isFirst != 'th4') {
                    let IDValueSArray = Array.from(new Set(this.showDataList.map(i => i.th4)));
                    this.IDOptions.forEach(i => {
                        if (IDValueSArray.includes(i.value)) {
                            i.disabled = false;
                        } else {
                            i.disabled = true;
                        }
                    })
                }
                if (this.isFirst != 'th6') {
                    let PackageSArray = Array.from(new Set(this.showDataList.map(i => i.th6)));
                    this.PackageOptions.forEach(i => {
                        if (PackageSArray.includes(i.value)) {
                            i.disabled = false;
                        } else {
                            i.disabled = true;
                        }
                    })
                }
            } else {
                this.VDSOptions.forEach(i => {
                    i.disabled = false;
                })
                this.RDSOptions.forEach(i => {
                    i.disabled = false;
                })
                this.IDOptions.forEach(i => {
                    i.disabled = false;
                })
                this.PackageOptions.forEach(i => {
                    i.disabled = false;
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.row-hover tr {
    background: #fff;
}

.row-hover tr:nth-child(2n) {
    background-color: #ebeff3;
}

.el-table th {
    background: #4aa3dc;
    font-weight: 700;
    vertical-align: middle;
    font-size: 16px;
    color: #fff;
    text-align: center;
}

#tablepress_length {
    margin-bottom: 25px;
    margin-top: 40px;

    ::v-deep .el-select {
        width: 150px;
        margin-right: 15px;
    }
}

@media (max-width: 768px) {
    #tablepress_length {

        ::v-deep .el-select {
            width: 100px;
            margin-bottom: 15px;
        }
    }
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
    background: #f7faff;
}

::v-deep .el-table th {
    background: #f2f7ff;
    color: #284777;
}
</style>
