<template>
    <section class="product-table">
        <div>
            <div class="entry-content-page">
                <div id="tablepress-scroll-wrapper" class="tablepress-scroll-wrapper">
                    <div id="tablepress-wrapper" class="dataTables_wrapper dataTables_wrapper_diode no-footer">
                        <div id="tablepress_wrapper" class="dataTables_wrapper no-footer">
                            <div class="dataTables_scroll" id="tablepress_length">
                                <el-select  multiple collapse-tags v-model="VRROValue" placeholder="VRRM" clearable @change="changeSelect('th2')">
                                    <el-option  :disabled="item.disabled" v-for="item in VRROOptions" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-select  multiple collapse-tags v-model="IfValue" placeholder="If(A)" clearable @change="changeSelect('th3')">
                                    <el-option  :disabled="item.disabled" v-for="item in IfOptions" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-select  multiple collapse-tags v-model="packageValue" placeholder="Package" clearable
                                    @change="changeSelect('th5')">
                                    <el-option  :disabled="item.disabled" v-for="item in packageOptions" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-button type="primary" @click="org()">重置</el-button>
                            </div>
                            <div class="dataTables_scroll">
                                <el-table :data="showDataList" height="500" stripe style="width: 100%;margin-bottom:50px">
                                    <el-table-column prop="th1" align="center" label="Part Number"  width="160">
                                    </el-table-column>
                                    <el-table-column prop="th2" align="center" label="VRRM"  width="120">
                                    </el-table-column>
                                    <el-table-column prop="th3" align="center" label="IF(A)"  width="120">
                                    </el-table-column>
                                    <el-table-column prop="th4" align="center" label="ID(A)">
                                    </el-table-column>
                                    <el-table-column prop="th5" align="center" label="Package">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'App',
    components: {
    },
    data() {
        return {
            dataList: [
                {
                    th1: 'SDC10065JAS', th2: '650', th3: '10', th4: '80', th5: 'TO-263-2',
                },
                {
                    th1: 'SDC20065JAS', th2: '650', th3: '20', th4: '160', th5: 'TO-263-2',
                },
                {
                    th1: 'SDC20120JAA', th2: '1200', th3: '20', th4: '160', th5: 'TO-263-2',
                },
                {
                    th1: 'SDC30120JAA', th2: '1200', th3: '30', th4: '240', th5: 'TO-263-2',
                },
                {
                    th1: 'SDC40120JAS', th2: '1200', th3: '40', th4: '300', th5: 'TO-263-2',
                },
                {
                    th1: 'SDC04065DAS', th2: '650', th3: '4', th4: '36', th5: 'TO-252-2',
                },
                {
                    th1: 'SDC06065DAS', th2: '650', th3: '6', th4: '48', th5: 'TO-252-2',
                },
                {
                    th1: 'SDC10065DAS', th2: '650', th3: '10', th4: '80', th5: 'TO-252-2',
                },
                {
                    th1: 'SDC20065DAS', th2: '650', th3: '20', th4: '160', th5: 'TO-252-2',
                },
                {
                    th1: 'SDC02120DAS', th2: '1200', th3: '2', th4: '24', th5: 'TO-252-2',
                },
                {
                    th1: 'SDC10120DAS', th2: '1200', th3: '10', th4: '90', th5: 'TO-252-2',
                },
                {
                    th1: 'SDC20120DAS', th2: '1200', th3: '20', th4: '160', th5: 'TO-252-2',
                },
                {
                    th1: 'SDC20065T3A1A', th2: '650', th3: '20', th4: '150', th5: 'TO-247-3',
                },
                {
                    th1: 'SDC20065T3AS', th2: '650', th3: '10*', th4: '80*', th5: 'TO-247-3',
                },
                {
                    th1: 'SDC30065T3AS', th2: '650', th3: '15*', th4: '112*', th5: 'TO-247-3',
                },
                {
                    th1: 'SDC40065T3AS', th2: '650', th3: '20*', th4: '80*', th5: 'TO-247-3',
                },
                {
                    th1: 'SDC20120T3AA', th2: '1200', th3: '10*', th4: '90*', th5: 'TO-247-3',
                },
                {
                    th1: 'SDC30120T3AS', th2: '1200', th3: '15*', th4: '130*', th5: 'TO-247-3',
                },
                {
                    th1: 'SDC40120T3AA', th2: '1200', th3: '20*', th4: '160*', th5: 'TO-247-3',
                },
                {
                    th1: 'SDC06065T2AS', th2: '650', th3: '6', th4: '48', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC08065T2AS', th2: '650', th3: '8', th4: '64', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC10065T2AS', th2: '650', th3: '10', th4: '80', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC16065T2AS', th2: '650', th3: '16', th4: '128', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC20065T2AS', th2: '650', th3: '20', th4: '160', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC02120T2AS', th2: '1200', th3: '2', th4: '24', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC05120T2AS', th2: '1200', th3: '5', th4: '45', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC10120T2AA', th2: '1200', th3: '10', th4: '90', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC15120T2AS', th2: '1200', th3: '15', th4: '130', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC20120T2AA', th2: '1200', th3: '20', th4: '160', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC30120T2AA', th2: '1200', th3: '30', th4: '240', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC40120T2AS', th2: '1200', th3: '40', th4: '300', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC50120T2AS', th2: '1200', th3: '50', th4: '350', th5: 'TO-247-2',
                },
                {
                    th1: 'SDC10065F2AS', th2: '650', th3: '10', th4: '80', th5: 'TO-220F-2',
                },
                {
                    th1: 'SDC08065NAS', th2: '650', th3: '8', th4: '64', th5: 'TO-220-2',
                },
                {
                    th1: 'SDC10065NAS', th2: '650', th3: '10', th4: '80', th5: 'TO-220-2',
                },
                {
                    th1: 'SDC16065NAS', th2: '650', th3: '16', th4: '128', th5: 'TO-220-2',
                },
                {
                    th1: 'SDC20065NAS', th2: '650', th3: '20', th4: '160', th5: 'TO-220-2',
                },
                {
                    th1: 'SDC05120NAS', th2: '1200', th3: '5', th4: '45', th5: 'TO-220-2',
                },
                {
                    th1: 'SDC10120NAS', th2: '1200', th3: '10', th4: '90', th5: 'TO-220-2',
                },
                {
                    th1: 'SDC20120NAS', th2: '1200', th3: '20', th4: '160', th5: 'TO-220-2',
                },
                {
                    th1: 'SDC60DS120MBH1', th2: '1200', th3: '60', th4: '420', th5: 'SOT-227',
                },
                {
                    th1: 'SDC100DS120MBH1', th2: '1200', th3: '100', th4: '700', th5: 'SOT-227',
                },
                {
                    th1: 'SDC10065D56AS', th2: '650', th3: '10', th4: '80', th5: 'DFN 5*6',
                },
                {
                    th1: 'SDC20065D56AS', th2: '650', th3: '20', th4: '160', th5: 'DFN 5*6',
                },
                {
                    th1: 'SDC10120D56AS', th2: '1200', th3: '10', th4: '90', th5: 'DFN 5*6',
                },
                {
                    th1: 'SDC20120D56AS', th2: '1200', th3: '20', th4: '160', th5: 'DFN 5*6',
                },


            ],
            VRROValue: '', // 第一个select数据
            IfValue: '', // 第二个select数据
            packageValue: '', // 第三个select数据
            VRROOptions: [
                {
                    value: '650',
                    label: '650'
                },
                {
                    value: '1200',
                    label: '1200'
                },
            ],
            IfOptions: [
                {
                    value: '2',
                    label: '2'
                },
                {
                    value: '4',
                    label: '4'
                },
                {
                    value: '5',
                    label: '5'
                },
                {
                    value: '6',
                    label: '6'
                },
                {
                    value: '8',
                    label: '8'
                },
                {
                    value: '10',
                    label: '10'
                },
                {
                    value: '15',
                    label: '15'
                },
                {
                    value: '16',
                    label: '16'
                },
                {
                    value: '20',
                    label: '20'
                },
                {
                    value: '30',
                    label: '30'
                },
                {
                    value: '40',
                    label: '40'
                },
                {
                    value: '50',
                    label: '50'
                },
                {
                    value: '60',
                    label: '60'
                },
                {
                    value: '100',
                    label: '100'
                },
                {
                    value: '10*',
                    label: '10*'
                }, {
                    value: '15*',
                    label: '15*'
                },
                {
                    value: '20*',
                    label: '20*'
                },
            ],
            packageOptions: [
                {
                    value: 'DFN 5*6',
                    label: 'DFN 5*6'
                },
                {
                    value: 'SOT-227',
                    label: 'SOT-227'
                },
                {
                    value: 'TO-220-2',
                    label: 'TO-220-2'
                },
                {
                    value: 'TO-220F-2',
                    label: 'TO-220F-2'
                },
                {
                    value: 'TO-247-2',
                    label: 'TO-247-2'
                },
                {
                    value: 'TO-247-3',
                    label: 'TO-247-3'
                },
                {
                    value: 'TO-252-2',
                    label: 'TO-252-2'
                },
                {
                    value: 'TO-263-2',
                    label: 'TO-263-2'
                },
            ],
            showDataList: [],
            isFirst:null,
        }
    },
    mounted() {
        this.setData()
    },
    methods: {
        org(){
            this.VRROValue = ''; // 
            this.IfValue = ''; // 
            this.packageValue = ''; // 
            this.isFirst = null;
            this.setData()
        },
        changeSelect(th) {
            if(!this.isFirst){
                this.isFirst = th;
            }
          
            this.setData()
        },
        setData() {
            this.showDataList = JSON.parse(JSON.stringify(this.dataList.sort(function (a, b) {
                return a.th3 - b.th3
            })));
            if(this.VRROValue.length<=0 && this.IfValue.length<=0 &&  this.packageValue.length<=0){
                this.isFirst = null;
            }
            if (this.VRROValue.length>0) {
                this.showDataList = this.showDataList.filter(item => {
                    return this.VRROValue.includes(item.th2);
                })
            }
            if (this.IfValue.length>0) {
                this.showDataList = this.showDataList.filter(item => {
                    return this.IfValue.includes(item.th3);
                })
            }
            if (this.packageValue.length>0) {
                this.showDataList = this.showDataList.filter(item => {
                    return this.packageValue.includes(item.th5);
                })
            }
            if (this.isFirst) {
                if(this.isFirst !='th2'){
                    let VRRArray = Array.from(new Set(this.showDataList.map(i => i.th2)));
                    this.VRROOptions.forEach(i =>{
                        if(VRRArray.includes(i.value)){
                            i.disabled = false;
                        }else {
                            i.disabled = true;
                        }
                    })
                }
                if(this.isFirst !='th3'){
                    let IfArray = Array.from(new Set(this.showDataList.map(i => i.th3)));
                    this.IfOptions.forEach(i =>{
                        if(IfArray.includes(i.value)){
                            i.disabled = false;
                        }else {
                            i.disabled = true;
                        }
                    })
                }
                if(this.isFirst !='th5'){
                    let PackageSArray = Array.from(new Set(this.showDataList.map(i => i.th5)));
                    this.packageOptions.forEach(i =>{
                        if(PackageSArray.includes(i.value)){
                            i.disabled = false;
                        }else {
                            i.disabled = true;
                        }
                    })
                }
            } else {
                this.VRROOptions.forEach(i =>{
                    i.disabled = false;
                }) 
                this.IfOptions.forEach(i =>{
                    i.disabled = false;
                }) 
                this.packageOptions.forEach(i =>{
                    i.disabled = false;
                }) 
            }
        }
    }
}
</script>

<style lang="less" scoped>
.row-hover tr {
    background: #fff;
}

.row-hover tr:nth-child(2n) {
    background-color: #ebeff3;
}

.el-table th {
    background: #4aa3dc;
    font-weight: 700;
    vertical-align: middle;
    font-size: 16px;
    color: #fff;
    text-align: center;
}

#tablepress_length {
    margin-bottom: 25px;
    margin-top: 40px;

    ::v-deep .el-select {
        width: 150px;
        margin-right: 15px;
    }
}
@media (max-width: 768px) {
    #tablepress_length {
    
        ::v-deep .el-select {
            width: 100px;
            margin-bottom: 15px;
        }
    }
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
    background: #F7FAFF;
}

::v-deep .el-table th {
    background: #f2f7ff;
    color: #284777;
}
</style>
