<template>
    <section class="product-table">
        <div>
            <div class="entry-content-page">
                <div id="tablepress-scroll-wrapper" class="tablepress-scroll-wrapper">
                    <div id="tablepress-wrapper" class="dataTables_wrapper dataTables_wrapper_diode no-footer">
                        <div id="tablepress_wrapper" class="dataTables_wrapper no-footer">
                            <div class="dataTables_scroll" id="tablepress_length">
                                <el-select   multiple collapse-tags v-model="BVOValue" placeholder="BV(V)" clearable @change="changeSelect('th2')">
                                    <el-option  :disabled="item.disabled" v-for="item in BVOptions" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-select   multiple collapse-tags v-model="IcValue" placeholder="Ic(A)@100℃" clearable
                                    @change="changeSelect('th3')">
                                    <el-option  :disabled="item.disabled" v-for="item in IcOptions" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-select   multiple collapse-tags v-model="PackageValue" placeholder="Package" clearable
                                    @change="changeSelect('th4')">
                                    <el-option  :disabled="item.disabled" v-for="item in PackageOptions" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-button type="primary" @click="org()">重置</el-button>
                            </div>
                            <div class="dataTables_scroll">
                                <el-table :data="showDataList" height="400" stripe style="width: 100%;margin-bottom:50px">
                                    <el-table-column prop="th1" align="center" label="Part Number"  width="160">
                                    </el-table-column>
                                    <el-table-column prop="th2" align="center" label="BV(V)"  width="120">
                                    </el-table-column>
                                    <el-table-column prop="th3" align="center" label="IC(A)@100℃"  width="120">
                                    </el-table-column>
                                    <el-table-column prop="th4" align="center" label="Package">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'App',
    components: {
    },
    data() {
        return {
            dataList: [
                {
                    th1: 'SMD80CU120MBA1', th2: '1200', th3: 'TBD', th4: 'SOT-227',
                },
                {
                    th1: 'SMD250HB120P6A1', th2: '1200', th3: '250', th4: '62mm',
                },
                {
                    th1: 'SMD100HB120P6A1', th2: '1200', th3: '100', th4: '62mm',
                },
                {
                    th1: 'SMD200HB120P6A1', th2: '1200', th3: '200', th4: '62mm',
                },
                {
                    th1: 'SMD600HB120P6A1', th2: '1200', th3: '600', th4: '62mm',
                },
                {
                    th1: 'SMD250HB120B1A1', th2: '1200', th3: '300', th4: 'B1',
                },
            ],
            BVOValue: '', // 第一个select数据
            IcValue: '', // 第二个select数据
            PackageValue: '', // 第三个select数据
            BVOptions: [
                {
                    value: '1200',
                    label: '1200'
                },
            ],
            IcOptions: [
                {
                    value: '100',
                    label: '100'
                },
                {
                    value: '200',
                    label: '200'
                },
                {
                    value: '250',
                    label: '250'
                },
                {
                    value: '300',
                    label: '300'
                },
                {
                    value: '600',
                    label: '600'
                },
                {
                    value: 'TBD',
                    label: 'TBD'
                },
            ],
            PackageOptions: [
                {
                    value: '62mm',
                    label: '62mm'
                },
                {
                    value: 'B1',
                    label: 'B1'
                },
                {
                    value: 'SOT-227',
                    label: 'SOT-227'
                },
            ],
            showDataList: [],
        }
    },
    mounted() {
        this.setData()
    },
    methods: {
        org(){
            this.BVOValue = ''; // 
            this.IcValue = ''; // 
            this.PackageValue = ''; // 
            this.isFirst = null;
            this.setData()
        },
        changeSelect(th) {
            if(!this.isFirst){
                this.isFirst = th;
            }
            
            this.setData()
        },
        setData() {
            this.showDataList = JSON.parse(JSON.stringify(this.dataList));
            if(this.BVOValue.length<=0 && this.IcValue.length<=0 &&  this.PackageValue.length<=0){
                this.isFirst = null;
            }
            if (this.BVOValue.length>0) {
                this.showDataList = this.showDataList.filter(item => {
                    return this.BVOValue.includes(item.th2);
                })
            }
            if (this.IcValue.length>0) {
                this.showDataList = this.showDataList.filter(item => {
                    return this.IcValue.includes(item.th3);
                })
            }
            if (this.PackageValue.length>0) {
                this.showDataList = this.showDataList.filter(item => {
                    return this.PackageValue.includes(item.th4);
                })
            }
            if (this.isFirst) {
                if(this.isFirst !='th2'){
                    let BVOptions = Array.from(new Set(this.showDataList.map(i => i.th2)));
                    this.BVOptions.forEach(i =>{
                        if(BVOptions.includes(i.value)){
                            i.disabled = false;
                        }else {
                            i.disabled = true;
                        }
                    })
                }
                if(this.isFirst !='th3'){
                    let IcOptions = Array.from(new Set(this.showDataList.map(i => i.th3)));
                    this.IcOptions.forEach(i =>{
                        if(IcOptions.includes(i.value)){
                            i.disabled = false;
                        }else {
                            i.disabled = true;
                        }
                    })
                }
                if(this.isFirst !='th4'){
                    let PackageOptions = Array.from(new Set(this.showDataList.map(i => i.th4)));
                    this.PackageOptions.forEach(i =>{
                        if(PackageOptions.includes(i.value)){
                            i.disabled = false;
                        }else {
                            i.disabled = true;
                        }
                    })
                }
            } else {
                this.BVOptions.forEach(i =>{
                    i.disabled = false;
                }) 
                this.IcOptions.forEach(i =>{
                    i.disabled = false;
                }) 
                this.PackageOptions.forEach(i =>{
                    i.disabled = false;
                }) 
            }
        }
    }
}
</script>

<style lang="less" scoped>
.row-hover tr {
    background: #fff;
}

.row-hover tr:nth-child(2n) {
    background-color: #ebeff3;
}

.el-table th {
    background: #4aa3dc;
    font-weight: 700;
    vertical-align: middle;
    font-size: 16px;
    color: #fff;
    text-align: center;
}

#tablepress_length {
    margin-bottom: 25px;

    ::v-deep .el-select {
        width: 150px;
        margin-right: 15px;
    }
}
@media (max-width: 768px) {
    #tablepress_length {
    
        ::v-deep .el-select {
            width: 100px;
            margin-bottom: 15px;
        }
    }
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
    background: #F7FAFF;
}

::v-deep .el-table th {
    background: #f2f7ff;
    color: #284777;
}
</style>
