<template>
  <div class="product-header">
    <div class="left">
      <img :src="headerData.leftImg" class="leftImg" alt="">
      <div class="middle">
        <div class="title">{{headerData.title}}</div>
        <img src="http://img.sicred.cn/assets/img/product/line.png" class="img" />
        <div class="contentText">{{headerData.contentText}}</div>
      </div>
    </div>
    <div class="right">
      <img :src="headerData.rightImg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props:['headerData']
    
  
}
</script>

<style lang="less" scoped>
.product-header{
  display: flex;
  justify-content: space-between;
  background-color: #f4f8fe;
  padding: 20px;
  margin-bottom: 42px;
}
.left{
  margin-left: 10px;
  display: flex;
  .leftImg{
    width: 170px;
    height: 170px;
  }
}
.middle{
  margin-left: 16px;
  .title{
    font-size: 30px;
    font-weight: 700;
    margin-top: 20px;
    
  }
  .contentText{
    color: #7F7F80;
    font-size: 14px;
  }
  .img{
    margin-top: 10px;
  }
}
.right{
  img{
    width: 190px;
    height: 150px;
  }
}
@media (max-width: 768px) {
    .middle{
        margin-left: 0px;
        .title{
            font-size: 20px;
        }
    }
    .left{
        display:block;
        margin-left: 0px;
        .leftImg{
            display: block;
            text-align: center;
            margin: 0 auto;
        }
    }
    .right{
        img{
            display: none;
            width: 190px;
            height: 150px;
        }
    }
    
}
</style>