<template>
  <section class="product-wrapper">
    <div class="container">
      <Crumbs name="产品介绍" />
      <div class="flex" >
        <el-menu
          :default-active="menuActive"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @select="handleMenu"
          @close="handleClose"
        >
          <el-menu-item index="1-1">
              <span class="menuTitle" slot="title">SIC MOSFET</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title" class="menuTitle">SiC SBD</span>
          </el-menu-item>
          <el-menu-item index="3">
            <span slot="title" class="menuTitle">SiC Module</span>
          </el-menu-item>
        </el-menu>
        <div class="product-content">
          <div>
            <ProductHeader :headerData="headerData" />
            <!-- SIC MOSFET -->
            <ProductListNew v-if="menuActive == '1-1'" />
            <!-- SiC SBD -->
            <ProductListNewTwo v-if="menuActive == 2" />
            <!-- SiC Module -->
            <ProductListNewThree v-if="menuActive == 3" />
          </div>
          <div class="form">
            <ApplicationForm />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Crumbs from "@/components/Crumbs";
import ProductHeader from "@/components/product/Product-header";
import ProductListNew from "@/page/product/components/product-list-new";
import ProductListNewTwo from "@/page/product/components/product-list-new-two";
import ProductListNewThree from "@/page/product/components/product-list-new-three";
import ApplicationForm from "@/components/ApplicationForm";
export default {
  name: "HelloWorld",
  components: {
    Crumbs,
    ProductHeader,
    ProductListNew,
    ProductListNewTwo,
    ProductListNewThree,
    ApplicationForm,
  },
  props: {
    msg: String,
  },
  mounted(){
    if(this.$route.query.menuActive){
      this.menuActive = this.$route.query.menuActive;
      this.handleMenu(this.menuActive)
    }
  },
  data() {
    return {
      menuActive: "1-1",
      headerData: {
        leftImg: require("@/assets/img/product/index-product-img01-in.png"),
        title: "SiC MOSFET 碳化硅场效应管系列",
        contentText: "具有高可靠性、高性能、和高良率等特点，可满足各种应用需求",
        rightImg: require("@/assets/img/product/index-product-img01.png"),
      },
      headerDataList: {
        "1-1": {
          leftImg: require("@/assets/img/product/index-product-img01-in.png"),
          title: "SiC MOSFET 碳化硅场效应管系列",
          contentText:
            "具有高可靠性、高性能、和高良率等特点，可满足各种应用需求",
          rightImg: require("@/assets/img/product/index-product-img01.png"),
        },
        2: {
          leftImg: require("@/assets/img/product/index-product-img02-in.png"),
          title: "SiC SBD 碳化硅肖特基⼆级管系列",
          contentText: "Trr极短、反向恢复电流小，显著降低反向恢复损耗，适合高频工作",
          rightImg: require("@/assets/img/product/index-product-img02.png"),
        },
        3: {
          leftImg: require("@/assets/img/product/index-product-img03-in.png"),
          title: "SiC MODULE 模块系列",
          contentText: "可承载100A-300A的电流密度，具有高能效、低损耗、系统成本优等特点",
          rightImg: require("@/assets/img/product/index-product-img03.png"),
        },
      },
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleMenu(key) {
      this.menuActive = key;
      this.$set(this, "headerData", this.headerDataList[key]);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.product-wrapper {
  background-color: #fff;
  padding-bottom: 40px;
}
.el-menu-vertical-demo {
  min-width: 300px;
  height: 100%;
  margin-right: 30px;
  border: 1px solid #eee;
  border-radius: 4px;
}
::v-deep.el-submenu .el-menu-item{
 padding: 13px 32px !important;
 line-height: 24px;
 height: auto;
}
::v-deep .el-menu-item {
  font-size: 14px;
}
::v-deep .el-menu-item.is-active {
  color: #fff;
  background-color: #4fbfe9;
  border-left: 5px solid #284777;
}
.menuTitle {
  font-size: 18px;
  font-weight: 700;
}
.flex {
  display: flex;
}
.product-content {
  padding: 0;
}
.form {
  margin-bottom: 60px;
}
@media (max-width: 768px) {
    .flex{
        display:block;
    }
    .el-menu-vertical-demo{
        width: 100%;
        margin-bottom: 20px;
        display: flex;
    }
    .menuTitle {
        font-size: 14px; 
    }
    ::v-deep .el-menu-item.is-active{
        overflow: hidden;
        text-align: center;
        border-left:0;
        flex: 1;
    }
    .form {
     margin-bottom: 20px;
    }
}
</style>
